/* payreport.css */

/* Overall layout */
.report-container {
    display: flex;
    transition: margin-left 0.3s ease-in-out;
  }
  .report-container.sidebar-open .report-content {
    margin-left: 250px; /* Adjust this value to fit your sidebar width */
  }
  
  /* Sidebar styling */
  .report-content {
    flex: 1;
    padding: 20px;
    transition: margin-left 0.3s ease-in-out;
    overflow-y: auto;
  }
  
  /* Header styling */
  h2 {
    font-size: 24px;
    color: #333;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Filter section */
  .filter-section {
    display: flex;
    align-items: center;
    gap: 30px; /* Increased spacing between elements */
    margin-bottom: 15px;
    flex-wrap: wrap; /* Ensures responsiveness on smaller screens */
  }
  .filter-section label {
    font-weight: bold;
    color: #555;
  }
  .filter-section input[type="date"][type="search"] {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1; /* Makes elements grow/shrink evenly */
  }
  .filter-section input[type="date"] [type="search"]:focus {
    box-shadow: 0 0 5px #007bff;
    border-color: #007bff;
  }
  
 
  
  
  /* Table styling */
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    animation: slideIn 0.5s ease-in-out;
  }
  table th,
  table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    color: #333;
  }
  table th {
  
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  table td {
    font-size: 15px;
    color: #333;
  }
  table tbody tr:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
  
  /* Totals summary */
  .totals-summary {
    margin-top: 20px;
    font-size: 16px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
  }
  .totals-summary:hover {
    background-color: #f1f1f1;
  }
  
  /* Orders summary popup */
  .orders-summary {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background:  #555;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    animation: popUp 0.5s ease-out;
  }
  .orders-summary h3 {
    margin-top: 0;
    font-size: 18px;
    color: #fff;
  }
  .orders-summary button {
    background: #ff6b6b;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  .orders-summary button:hover {
    background: #ff4b4b;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes popUp {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .report-container.sidebar-open .report-content {
      margin-left: 0;
    }
    table th, table td {
      font-size: 14px;
      padding: 8px 10px;
    }
    .totals-summary {
      font-size: 14px;
    }
    .orders-summary {
      bottom: 10px;
      right: 10px;
      padding: 15px;
    }
  }
  .confirm-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .confirm-popup p {
    margin-bottom: 20px;
  }
  
  .confirm-popup button {
    margin: 0 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-popup button:first-of-type {
    background-color: #28a745;
    color: white;
  }
  
  .confirm-popup button:last-of-type {
    background-color: #dc3545;
    color: white;
  }
  .export-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .export-button:hover {
    background-color: #45a049;
  }
  /* For the overlay background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* For the popup content */
.popup-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  animation: slideUp 0.4s ease-in-out;
}

/* For confirm and cancel buttons */
.popup-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
}

.confirm-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #e53935;
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
