/* Container styles */
.add-ingredient-container {
  max-width: 600px; /* Increased width for better spacing */
  margin: 40px auto;
  padding: 25px 35px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  font-family: 'Arial', sans-serif;
  margin-top: 100px;
}

/* Title */
.add-ingredient-title {
  font-size: 24px;
  color: #2a3f54;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
}

/* Input fields */
.add-ingredient-input,
.add-ingredient-select {
  width: 100%;
  padding: 14px;
  margin-bottom: 20px; /* Increased spacing for better readability */
  font-size: 16px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.add-ingredient-input:focus,
.add-ingredient-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 6px rgba(42, 63, 84, 0.5);
  outline: none;
}

/* Suggestions dropdown */
.suggestions-list {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  max-height: 150px; /* Limit height for scrolling */
  overflow-y: auto;
}

.suggestion-item {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
}

.suggestion-item:hover {
  background-color: #007bff;
  color: #fff;
}

/* Button */
.add-ingredient-button {
  width: 100%;
  padding: 14px;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.add-ingredient-button:hover {
  background-color: #0056b3;
  transform: scale(1.02); /* Slight hover effect */
}

/* Button focus state */
.add-ingredient-button:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(42, 63, 84, 0.8);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .add-ingredient-container {
    padding: 20px;
  }

  .add-ingredient-title {
    font-size: 20px;
  }

  .add-ingredient-input,
  .add-ingredient-select {
    font-size: 14px;
    padding: 12px;
  }

  .add-ingredient-button {
    font-size: 15px;
    padding: 12px;
  }

  .suggestion-item {
    font-size: 13px;
  }
}
