/* VendorForm.css */

/* General Styles */
.vendor-form {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light background for contrast */
}

.vendor-form__title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Form Container */
.vendor-form__form {
    max-width: 600px; /* Increased width for better spacing */
    margin: 40px auto;
    padding: 25px 35px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    font-family: 'Arial', sans-serif;
    margin-top: 100px;
}

/* Input Styles */
.vendor-form__input {
    width: 100%;
    padding: 12px;
    margin: 10px 0; /* Spacing between inputs */
    border: 1px solid #ced4da; /* Light border */
    border-radius: 4px; /* Rounded corners */
    box-sizing: border-box; /* Ensure padding is included in width */
    transition: border-color 0.3s; /* Smooth border color transition */
}

.vendor-form__input:focus {
    border-color: #80bdff; /* Blue border on focus */
    outline: none; /* Remove default outline */
}

/* Checkbox Styles */
.vendor-form__label {
    display: block;
    margin: 10px 0; /* Spacing between labels */
    cursor: pointer; /* Pointer cursor for labels */
}

.vendor-form__checkbox {
    margin-right: 10px; /* Space between checkbox and label text */
}

/* Category and Item Heading Styles */
.vendor-form__category-title,
.vendor-form__item-title {
    color: #555; /* Darker grey for headings */
}

.vendor-form__item-title {
    margin-top: 15px; /* Space before item headings */
}

/* Button Styles */
.vendor-form__button {
    width: 100%;
    padding: 12px;
    background-color: #007bff; /* Primary blue color */
    color: #fff; /* White text */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s; /* Smooth background transition */
}

.vendor-form__button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .vendor-form__form {
        padding: 15px; /* Less padding on smaller screens */
    }

    .vendor-form__button {
        font-size: 14px; /* Smaller button text on smaller screens */
    }
}

.vendor-form__dropdown {
    margin: 10px 0;
    width: 100%;
    font-size: 14px;
  }
  
  .vendor-form__category-items {
    margin: 20px 0;
  }
  
  .vendor-form__item-title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  