body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f6f8;
  }
  
  /* Container Styling */
  .dashboard-container {
    display: flex;
    transition: margin-left 0.3s ease;
  }
  .sidebar-open .dashboard-content {
    margin-left: 250px; /* Sidebar width */
  }
  
  /* Sidebar Styling */
  .dashboard-container .UserSidebar {
    width: 250px;
    background-color: #333;
    color: #fff;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    transition: width 0.3s ease;
  }
  .dashboard-container .UserSidebar.closed {
    width: 0;
  }
  
  /* Dashboard Content */
  .dashboard-content {
    flex: 1;
    margin-left: 0px;
    padding: 20px;
    transition: margin-left 0.3s ease;
  }
  .dashboard-content h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Header Styling */
  .UserHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a3f54;
    color: #fff;
    padding: 10px 20px;
  }
  .UserHeader button {
    background: none;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
  }
  
.vendor-dashboard {
    display: flex;
    flex-direction: column; /* Ensures the items are stacked vertically */
    gap: 20px;
    padding: 20px;
    align-items: flex-start; /* Aligns the cards to the left side */
  }
  
  .vendor-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 100%; /* Ensures it takes up full width */
    max-width: 600px; /* Optional: controls the max width of each card */
    margin-bottom: 15px; /* Adds space between each vendor card */
    background-color: #f9f9f9;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .add-product-button {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .add-product-button:hover {
    background-color: #007bff;
  }
  .add-product-button svg {
    margin-right: 5px;
  }
  .vendor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .vendor-header h2 {
    font-size: 18px;
    margin: 0;
  }
  
  .edit-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-btn:disabled {
    background-color: #ccc;
  }
  
  .vendor-details {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .vendor-details p {
    margin: 5px 0;
  }
  
  .view-more-btn {
    background-color: transparent;
    border: none;
    color: #4caf50;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .view-more-btn:hover {
    background-color: transparent;
    border: none;
    color: #4caf50;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .view-more-btn svg {
    margin-right: 5px;
  }
  
  .stock-details {
    margin-top: 15px;
  }
  
  .stock-details table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .stock-details th, .stock-details td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .save-btn {
    background-color: #4caf50;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .save-btn:hover {
    background-color: #388e3c;
  }
  
  .save-btn:disabled {
    background-color: #ccc;
  }
  .dashboard-container {
    display: flex;
  }
  

  
  .add-vendor-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
  }
  
  .add-vendor-button:hover {
    background-color: #45a049;
  }
  .search-bar {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px;
    font-size: 16px;
    width: 100%;
    max-width: 300px;
  }
  
  .export-btn {
    margin-top: 20px;
    padding: 8px;
    font-size: 16px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .export-btn:hover {
    background-color: #218838;
  }
  .vendor-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .vendor-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 20px;
    width: 100%;
    padding: 20px;
  }
  
  
  .comment-section {
    margin-top: 20px;
  }
  
  .comment-inputs {
    display: flex;
    flex-direction: column;
  }
  
  .comment-inputs input {
    padding: 8px;
    margin: 5px 0;
  }
  
  .comments {
    margin-top: 20px;
  }
  
  .comment {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
    background-color: #f9f9f9;
  }
  

  