/* App.css */

/* Root Variables */
:root {
    --primary-color: #4caf50;
    --secondary-color: #333;
    --background-color: #f9f9f9;
    --text-color: #333;
    --font-heading: 'Arial', sans-serif;
    --font-body: 'Arial', sans-serif;
  }
  
  /* Global Styles */
  body {
    margin: 0;
    font-family: var(--font-body);
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .app {
    text-align: center;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .nav a {
    margin: 0 15px;
    text-decoration: none;
    color: var(--secondary-color);
  }
  
  .nav a:hover {
    color: var(--primary-color);
  }
  
  .header-buttons .login-btn {
    background: none;
    border: none;
    color: var(--secondary-color);
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .header-buttons .cta-btn {
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .header-buttons .cta-btn:hover {
    background-color: #3d8c40;
  }
  
  /* Hero Section */
  .hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }
  
  .hero-text {
    max-width: 50%;
    text-align: left;
  }
  
  .hero-text h1 {
    font-size: 36px;
    color: var(--secondary-color);
    margin-bottom: 20px;
  }
  
  .hero-text span {
    color: var(--primary-color);
    font-weight: bold;
  }
  
  .hero-text p {
    font-size: 18px;
    margin: 20px 0;
    line-height: 1.6;
  }
  
  .hero-text .cta-btn {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .hero-text .cta-btn:hover {
    background-color: #3d8c40;
  }
  
  .hero-image img {
    width: 700px;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
/* Feature Section Container */
.feature-section {
    background-color: #f8f9fa; /* Light background for contrast */
    padding: 60px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Header Section */
  .feature-header h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .feature-header p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  /* Feature Grid */
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: start;
    padding: 0 10px;
  }
  
  /* Individual Feature Cards */
  .feature-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Section */
  .feature-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #28a745; /* Green accent */
  }
  
  /* Card Title */
  .feature-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Card Description */
  .feature-card p {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.6;
  }
/* Inventory Section Styling */
.inventory-section {
    background-color: #ffffff; /* White background */
    padding: 60px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Header Styling */
  .inventory-header h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .inventory-header h2 span {
    color: #28a745; /* Green accent for the hashtag */
  }
  
  .inventory-header p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  /* Grid Layout */
  .inventory-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: stretch; /* Ensures all cards are the same height */
    padding: 0 10px;
  }
  
  /* Individual Feature Cards */
  .inventory-card {
    background: #f9f9f9; /* Light grey for card background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column; /* Ensures proper layout inside */
    justify-content: space-between; /* Distributes content evenly */
    height: 100%; /* Stretch cards to match height */
  }
  .inventory-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Styling */
  .inventory-icon {
    font-size: 3rem;
    margin-bottom: 15px;
    color: #28a745; /* Green accent */
  }
  
  /* Card Title */
  .inventory-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Card Description */
  .inventory-card p {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.6;
  }
  .promo {
    background-color:  #f8faff;
    padding: 3rem 2rem;
    text-align: center;
  }
  
  .promo-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .promo-text {
    flex: 1;
    min-width: 300px;
    padding: 1rem;
  }
  
  .promo-text h1 {
    font-size: 2.5rem;
    line-height: 1.5;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .promo-text h1 span {
    color: green;
    font-weight: bold;
  }
  
  .promo-text p {
    font-size: 1rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 2rem;
  }
  
  .promo-button {
    background-color: green;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .promo-button:hover {
    background-color: darkgreen;
  }
  
  .promo-image {
    flex: 1;
    min-width: 600px;
    padding: 1rem;
  }
  
  .promo-image img {
    max-width: 100%;
    border-radius: 10px;
  }
  .testimonials {
    background-color: #fff;
    padding: 4rem 2rem;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  /* Testimonials Section */
/* Testimonials Section */
.testimonials {
    background: #f8faff; /* Subtle light blue background */
    padding: 3rem 1rem;
  }
  
  .testimonials-row {
    display: flex;
    align-items: flex-start;
    gap: 2rem; /* Space between summary and grid */
    flex-wrap: wrap; /* Ensure proper layout on smaller screens */
  }
  
  /* Summary Section */
  .testimonial-summary {
    max-width: 400px;
    text-align: center;
   margin-left: 37%; /* Align text to the left */
  }
  
  .testimonial-summary h4 {
    font-size: 1.2rem;
    color: #28a745; /* Green accent */
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
  }
  
  .testimonial-summary h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .testimonial-summary p {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }
  
  .view-all-button {
    background: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .view-all-button:hover {
    background: #1e7b34;
  }
  
  /* Testimonial Cards Section */
  .testimonials-grid {
    display: flex;
    flex: 2; /* Takes the remaining space in the row */
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .testimonial-card {
    background: #ffffff;
    border-radius: 15px;
    text-align: center;
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 280px;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .client-image {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 1rem;
    border: 3px solid #28a745;
  }
  
  .testimonial-card h3 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .testimonial-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .testimonial-card .designation {
    font-style: italic;
    color: #777;
  }
  
  .testimonial-card .share-link {
    display: inline-block;
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #28a745;
    text-decoration: none;
    font-weight: bold;
  }
  
  .testimonial-card .share-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .testimonials-row {
      flex-direction: column; /* Stack summary and grid */
      align-items: center;
    }
  
    .testimonial-summary {
      max-width: 100%;
      text-align: center; /* Center-align text for smaller screens */
    }
  
    .testimonials-grid {
      justify-content: center; /* Center-align cards */
    }
  }
 /* Pricing Table Section */
.pricing-table-container {
    text-align: center;
    padding: 60px 20px; /* Adjusted padding for better spacing */
    background-color: #f8faff; /* Subtle light blue background for better contrast */
  }
  
  .pricing-title {
    font-size: 2.5rem; /* Larger for emphasis */
    color: #28a745; /* Green accent */
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .pricing-description {
    color: #666; /* Softer color for better readability */
    font-size: 1.1rem;
    margin-bottom: 40px; /* Added more spacing for separation */
  }
  
  /* Pricing Cards Layout */
  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 30px; /* Increased gap for cleaner layout */
    flex-wrap: wrap; /* Ensures responsiveness */
  }
  
  /* Individual Pricing Card */
  .pricing-card {
    background: #ffffff;
    border-radius: 15px; /* Softer rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
    padding: 30px; /* Increased padding for spacious design */
    max-width: 300px; /* Adjusted max width */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  }
  
  .pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  }
  
  /* Pricing Icon */
  .pricing-icon {
    font-size: 3.5rem; /* Slightly larger for emphasis */
    color: #28a745;
    margin-bottom: 20px;
  }
  
  /* Pricing Name */
  .pricing-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Pricing Features List */
  .pricing-features {
    list-style: none;
    padding: 0;
    margin-bottom: 25px;
  }
  
  .pricing-feature {
    color: #555;
    font-size: 1rem;
    margin: 10px 0;
    display: flex; /* Align feature items neatly */
    align-items: center;
    gap: 10px; /* Space between icon and text */
  }
  
  .pricing-feature i {
    color: #28a745;
    font-size: 1.2rem; /* Slightly larger for better visibility */
  }
  
  /* Pricing Amount */
  .pricing-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 2.2rem; /* Highlighted pricing */
    color: #28a745;
  }
  
  .period {
    font-size: 1rem;
    color: #555;
  }
  
  /* Buy Now Button */
  .buy-now-button {
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 25px; /* Rounded button for a modern look */
    padding: 12px 25px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .buy-now-button:hover {
    background-color: #218838;
    transform: scale(1.05); /* Slight enlargement on hover */
  }
  .modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.3s forwards; /* Fade-in animation */
    z-index: 1000;
  }
  
  /* Modal content with scale-up animation */
  .modal-content1 {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    transform: scale(0.8);
    animation: scaleUp 0.3s forwards; /* Scale-up animation */
    position: relative;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: #555;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #000;
    background-color: #28a745;

  }
  
  /* Form styling */
  .demo-form input,
  .demo-form button {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
  }
  
  .demo-form input:focus {
    outline: none;
    border-color: #28a745;
  }
  
  .demo-form button {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .demo-form button:hover {
    background-color: #28a745;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .pricing-cards {
      flex-direction: column; /* Stack cards vertically */
      align-items: center;
    }
  
    .pricing-card {
      max-width: 90%; /* Adjust width for smaller screens */
      margin-bottom: 20px;
    }
  }
  /* Footer Section */
  :root {
    --primary-color: #28a745;
    --secondary-color: #555;
    --background-color: #f4f4f4;
    --text-color: #444;
    --hover-color: #218838;
}



.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px; /* Added gap for better spacing */
}

/* Logo Section */
.footer-logo-section {
    flex: 1 1 300px;
    text-align: center;
    margin-bottom: 20px; /* Added spacing for smaller screens */
}

.footer-logo {
    width: 120px; /* Slightly larger logo size */
    margin-bottom: 15px;
}

.footer-description {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1.6; /* Improved readability */
    color: var(--secondary-color);
}

/* Contact Information */
.footer-contact p {
    font-size: 14px;
    margin: 5px 0;
    color: var(--secondary-color);
}

/* Social Icons */
.footer-social-icons {
    margin-top: 20px;
    font-size: 24px; /* Slightly larger for better visibility */
}

.footer-social-icons i {
    margin: 0 10px;
    color: var(--primary-color);
    cursor: pointer;
    transition: color 0.3s, transform 0.3s;
}

.footer-social-icons i:hover,
.footer-social-icons i:focus {
    color: var(--hover-color);
    transform: scale(1.1); /* Slight enlargement on hover */
    outline: 2px solid var(--hover-color); /* Focus effect for accessibility */
    outline-offset: 2px;
}

/* Footer Links Section */
.footer-links {
    display: flex;
    flex: 2 1 600px;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px; 
}

.footer-column {
    flex: 1 1 200px;
    margin: 10px;
}

.footer-column h3 {
    font-size: 16px;
    margin-bottom: 15px;
    color: var(--primary-color);
    font-weight: bold; /* Emphasized headings */
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li {
    font-size: 14px;
    margin: 8px 0;
    color: var(--secondary-color);
    transition: color 0.3s;
}

.footer-column ul li:hover {
    color: var(--primary-color); /* Highlighted color on hover */
}

/* Bottom Footer Section */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the page fills at least the full height of the viewport */
}

.content {
  flex: 1; /* This takes up all available space */
}

/* Footer Styles */
.footer {
  background-color: var(--background-color);
  padding: 60px 20px;
  font-family: Arial, sans-serif;
  color: var(--text-color);
}

/* Other footer styles remain the same */

/* Footer Bottom (Sticky) */
.footer-bottom {
  text-align: center;
  padding: 15px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 14px;
  margin-top: auto; /* Pushes the copyright section to the bottom */
  border-radius: 5px;
}



/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column; /* Stack sections vertically */
        align-items: center;
    }

    .footer-links {
        justify-content: center; /* Center-align links on smaller screens */
    }

    .footer-column {
        text-align: center;
        flex: 1 1 100%; /* Full-width columns */
    }

    .footer-logo-section {
        margin-bottom: 30px; /* Added spacing for better separation */
    }

    .footer-bottom {
        font-size: 13px;
        padding: 10px;
    }
}

@media (max-width: 576px) {
    .footer-logo {
        width: 100px; /* Adjust logo size for very small devices */
    }

    .footer-column ul li {
        font-size: 13px; /* Adjust font size for better readability */
    }

    .footer-bottom {
        font-size: 12px;
    }
}

  
              